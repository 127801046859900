import { Slide } from "react-toastify";

const DB_NAME = {
  AI_TOOLS: "AI-Tools",
};

const TOAST_CONTAINER_CONFIG = {
  autoClose: 2000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  transition: Slide,
};

const AI_TOOL_CATEGORY = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Trending",
    value: "Trending",
  },
  {
    label: "Writing",
    value: "Writing",
  },
  {
    label: "Design",
    value: "Design",
  },
  {
    label: "Assistant",
    value: "Assistant",
  },
  {
    label: "Image",
    value: "Image",
  },
  {
    label: "Code",
    value: "Code",
  },
  {
    label: "Video",
    value: "Video",
  },
  {
    label: "Audio",
    value: "Audio",
  },
  {
    label: "Productivity",
    value: "Productivity",
  },
];

const DEFAULT_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/aibuddytest.appspot.com/o/AI-tools-images%2FDefault.jpg?alt=media&token=1bc90b9f-6627-492a-be51-903e2c27ab7a";

export default { TOAST_CONTAINER_CONFIG, AI_TOOL_CATEGORY, DB_NAME, DEFAULT_IMAGE };
