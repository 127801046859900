import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser, logout } = useAuth();
  const [page, setPage] = useState("");

  useEffect(() => {
    const urlArray = pathname.split("/");
    setPage(urlArray[urlArray.length - 1]);
  }, [pathname]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="header">
      <div className="float-right">
        {currentUser ? (
          <div>
            <button
              className="mt-4 me-4 p-1 px-2 outline outline-white rounded-lg"
              onClick={() => handleLogout()}
            >
              Log Out
            </button>
          </div>
        ) : (
          <div className="flex justify-center items-center ">
            <button
              className="mt-2 mx-4 p-2"
              onClick={() => handleNavigate("/login")}
            >
              Login
            </button>
            <button
              className="mt-3 me-4 p-1 px-2 outline outline-white rounded-lg"
              onClick={() => handleNavigate("/signup")}
            >
              Sign Up
            </button>
          </div>
        )}
      </div>

      <div className="flex justify-center items-center ">
        <p
          className={`text-base cursor-pointer mt-3 ${
            page === "submit" && "underline underline-offset-4"
          }`}
          onClick={() => handleNavigate("/submit")}
        >
          Submit
        </p>
        <p
          className="px-7 font-bold text-3xl cursor-pointer mt-2"
          onClick={() => handleNavigate("/")}
        >
          The AI Helper
        </p>
        {/* <p
          className={`text-base cursor-pointer mt-3 ${
            page === "news" && "underline underline-offset-4"
          }`}
          onClick={() => handleNavigate("/news")}
        >
          News
        </p> */}
      </div>
    </div>
  );
};

export default Header;
